import { environment } from "./../../../environments/environment";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
// import { BqAuthService } from '../../bq-auth.service'

@Injectable()
export class CommonDataService {
  constructor(private http: HttpClient) {}

  getBackendStatus(): Observable<any> {
    return this.http.get(environment.apiUrl + "/");
  }

  getCommonData(): Observable<any> {
    return this.http.get(environment.apiUrl + "/stores/common-entitys");
  }

  getStoreList(idgroup?): Observable<any> {
    console.log("Consulta de tiendas", idgroup);
    if (idgroup != undefined) {
      return this.http.get(
        environment.apiUrl + "/stores/list?idgroup=" + idgroup
      );
    } else {
      console.log("es vacio");
      return null;
    }
  }

  getStoreDivisions(idgroup?): Observable<any> {
    console.log("Consulta de divisones", idgroup);
    return this.http.get(
      environment.apiUrl + "/stores/divisions?idgroup=" + idgroup
    );
  }

  async exportHP(start: string, end: string, store: string): Promise<Blob> {
    const file = await this.http
      .get<Blob>(
        environment.apiUrl +
          "/export/hp?s=" +
          start +
          "&e=" +
          end +
          "&st=" +
          store,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  async exportMonthlyReport(month: string): Promise<Blob> {
    const file = await this.http
      .get<Blob>(environment.apiUrl + "/export/monthly-report?m=" + month, {
        responseType: "blob" as "json",
      })
      .toPromise();
    return file;
  }

  async exportNPS(start: string, end: string, store: string): Promise<Blob> {
    const file = await this.http
      .get<Blob>(
        environment.apiUrl +
          "/export/nps?s=" +
          start +
          "&e=" +
          end +
          "&st=" +
          store,
        { responseType: "blob" as "json" }
      )
      .toPromise();

    return file;
  }

  async exportGMB(): Promise<Blob> {
    const file = await this.http
      .get<Blob>(environment.apiUrl + "/export/gmb", {
        responseType: "blob" as "json",
      })
      .toPromise();
    return file;
  }

  async exportEverest(
    start: string,
    end: string,
    store: string
  ): Promise<Blob> {
    const file = await this.http
      .get<Blob>(
        environment.apiUrl +
          "/export/everest?s=" +
          start +
          "&e=" +
          end +
          "&st=" +
          store,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getTrackingByStore(storeId: string): Observable<any> {
    let url = environment.apiUrl + "/admin/ticket-tracking-detail/" + storeId;

    return this.http.get(url);
  }

  getTracking(): Observable<any> {
    let url = environment.apiUrl + "/admin/ticket-tracking";

    return this.http.get(url);
  }
  getBrands() {
    let url = environment.brdUrl + "/brands/all";
    return this.http
      .get(url)
      .toPromise()
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((err) => {
        console.log("Error ", err);
      });
  }
  getBrandsUniverso(id) {
    let url = environment.brdUrl + "/brands/universe/" + id;
    return this.http
      .get(url)
      .toPromise()
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((err) => {
        console.log("Error ", err);
      });
  }
  getGlobalBrands() {
    let url = environment.brdUrl + "/brands/global/";
    return this.http
      .get(url)
      .toPromise()
      .then((res) => {
        console.log("Global", res);
        return res;
      })
      .catch((err) => {
        console.log("Error ", err);
      });
  }
  getDataOfBrands(_id, start, end) {
    let url =
      environment.brdUrl +
      "/brands/data/?id=" +
      _id +
      "&start=" +
      start +
      "&end=" +
      end;
    return this.http
      .get(url)
      .toPromise()
      .then((res: any) => {
        if (res.length > 0) {
          console.log("simple", res[0].result[0]);
          return res[0].result[0];
        } else {
          return null;
        }
      })
      .catch((err) => {
        console.log("Error ", err);
      });
  }

  getOrganization() {
    let url = environment.apiUrl + "/organization/all/";
    return this.http
      .get(url)
      .toPromise()
      .then((res: any) => {
        console.log("Global", res);
        return res.org;
      })
      .catch((err) => {
        console.log("Error ", err);
      });
  }
  getRoles() {
    let url = environment.apiUrl + "/role/all/";
    return this.http
      .get(url)
      .toPromise()
      .then((res: any) => {
        console.log("Global", res);
        return res.org;
      })
      .catch((err) => {
        console.log("Error ", err);
      });
  }
}
