import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AddHeaderInterceptor } from "./services/common/http.service";
import { ThemeModule } from "./theme/theme.module";
import { UsersModule } from "./users/users.module";
import { OrganizationModule } from "./organization/organization.module";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { ReactiveFormsModule } from "@angular/forms";
import { BqAuthService } from "./services/auth/bq-auth.service";
import { CommonDataService } from "./services/common/commonData.services";
import { ExcelService } from "./services/common/ExcelService.services";
import { HttpClientModule } from "@angular/common/http";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { RecoveryComponent } from './recovery/recovery.component';

@NgModule({
  declarations: [AppComponent, LoginComponent, DashboardComponent, RecoveryComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ThemeModule,
    UsersModule,
    OrganizationModule,
  ],

  providers: [
    BqAuthService,
    ExcelService,
    CommonDataService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [ThemeModule],
})
export class AppModule {}
