import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { BqAuthService } from "../../services/auth/bq-auth.service";
@Injectable()
export class UserServices {
  constructor(private http: HttpClient, private auth: BqAuthService) {}
  getUsers() {
    let url = environment.apiUrl + "/users/all";
    return this.http
      .get(url)
      .toPromise()
      .then((res: any) => {
        console.log(res);
        return res.usuario;
      })
      .catch((err) => {
        console.log("Error ", err);
      });
  }
  createUser(userData) {
    let url = environment.apiUrl + "/users";
    return this.http
      .post(url, { userData })
      .toPromise()
      .then((res: any) => {
        console.log(res);
        return res;
      })
      .catch((err) => {
        console.log("Error ", err);
      });
  }
  updateUser(id, userData) {
    let url = environment.apiUrl + "/users/" + id;
    return this.http
      .put(url, userData)
      .toPromise()
      .then((res: any) => {
        console.log(res);
        return res;
      })
      .catch((err) => {
        console.log("Error ", err);
      });
  }
  connectCVM(userData) {
    let url = environment.cvmUrl + "/connect-user";
    return this.http
      .post(url, { userData })
      .toPromise()
      .then((res: any) => {
        console.log(res);
        return res;
      })
      .catch((err) => {
        console.log("Error ", err);
      });
  }
  connectECM(userData) {
    let url = environment.ecmUrl + "/connect-user";
    return this.http
      .post(url, { userData })
      .toPromise()
      .then((res: any) => {
        console.log(res);
        return res;
      })
      .catch((err) => {
        console.log("Error ", err);
      });
  }
  connectBVM(userData) {
    let url = environment.brdUrl + "/connect-user";
    return this.http
      .post(url, { userData })
      .toPromise()
      .then((res: any) => {
        console.log(res);
        return res;
      })
      .catch((err) => {
        console.log("Error ", err);
      });
  }
  getGroups() {
    let url = environment.cvmUrl + "/public-group";
    return this.http
      .get(url)
      .toPromise()
      .then((res: any) => {
        console.log(res);
        return res;
      })
      .catch((err) => {
        console.log("Error ", err);
      });
  }
  getGroupsBVM() {
    let url = environment.brdUrl + "/public-group";
    return this.http
      .get(url)
      .toPromise()
      .then((res: any) => {
        console.log(res);
        return res;
      })
      .catch((err) => {
        console.log("Error ", err);
      });
  }
  getGroupsECM() {
    let url = environment.ecmUrl + "/public-group";
    return this.http
      .get(url)
      .toPromise()
      .then((res: any) => {
        console.log(res);
        return res;
      })
      .catch((err) => {
        console.log("Error ", err);
      });
  }
  connectEVM(userData) {
    let url = environment.evmUrl + "/connect-user";
    return this.http
      .post(url, { userData })
      .toPromise()
      .then((res: any) => {
        console.log(res);
        return res;
      })
      .catch((err) => {
        console.log("Error ", err);
      });
  }
  getGroupsEVM() {
    let url = environment.evmUrl + "/companies";
    return this.http
      .get(url)
      .toPromise()
      .then((res: any) => {
        console.log(res);
        return res;
      })
      .catch((err) => {
        console.log("Error ", err);
      });
  }
  inactiveUsers() {
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(environment.apiUrl + "/users/user-inactive")
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

    return promise;
  }
  activityUsersAdmin() {
    let usr = this.auth.getUserData();
    console.log(usr.user._id);
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(environment.apiUrl + "/users/user-activity?token=" + usr.user._id)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

    return promise;
  }
  changePassword(nPassword, token) {
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(environment.apiUrl + "/change-password", {
          nPassword: nPassword,
          token: token,
        })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

    return promise;
  }
}
