import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BqAuthService } from "../services/auth/bq-auth.service";
import { Router } from "@angular/router";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  constructor(private _bqAuth: BqAuthService, public router: Router) {}

  ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl("", [
        Validators.required,
        Validators.maxLength(60),
        Validators.email,
      ]),
      pass: new FormControl("", [
        Validators.required,
        Validators.maxLength(20),
      ]),
    });
  }
  login() {
    if (this.loginForm.valid) {
      this._bqAuth
        .login(
          this.loginForm.controls.email.value,
          this.loginForm.controls.pass.value
        )
        .then((res) => {
          console.log(res);
          this.router.navigate(["dashboard"]);
        })
        .catch((err) => {
          console.log(err);

          // this._loginError = true;

          // setTimeout(() => {
          //   this._loginError = false;
          // }, 4000);
        });
    }
  }
}
