import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

//material
import {MatToolbarModule  } from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatCardModule} from '@angular/material/card';
import { MenuComponent } from './menu/menu.component';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';
import {MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatChipsModule} from '@angular/material/chips';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';

//provides
import { MenuServices } from './services/menu.service';

const matVars = [
  HeaderComponent,
  MenuComponent
];
const materialVars = [
  MatToolbarModule,
  MatButtonModule,
  MatIconModule,
  MatSidenavModule,
  MatCardModule,
  MatListModule,
  MatMenuModule,
  MatDividerModule,
  MatTableModule,
  MatInputModule,
  ReactiveFormsModule,
  MatSelectModule,
  MatCheckboxModule,
  MatPaginatorModule,
  MatSortModule,
  MatChipsModule,
  MatExpansionModule,
  MatSlideToggleModule,
  FormsModule  ,
  MatSnackBarModule
]
@NgModule({
  declarations: [matVars, MenuComponent],
  imports: [
    CommonModule,
    materialVars
    
  ],
  exports:[
    matVars , 
    materialVars
  ],
  providers:[
    MenuServices
  ]
})
export class ThemeModule { }
