import { Component, OnInit , Injectable} from '@angular/core';
// import {  DashboardComponent } from '../../dashboard/dashboard.component';
import { Router } from '@angular/router';
import { MenuServices } from "../services/menu.service"
  import { from } from 'rxjs';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
@Injectable()
export class HeaderComponent implements OnInit {

  constructor(  private router: Router , private menu: MenuServices) { }

  ngOnInit() {
  }
    toggleRightSidenav() {
      console.log('Open Menu');
      this.menu.openMenu();
  }
  logout(){
    localStorage.clear();
    this.router.navigate(['login']);
  }
}
