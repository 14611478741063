import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrganizationComponent } from './organization.component';
import { ListComponent} from './list/list.component'
import { from } from 'rxjs';

const routes: Routes = [
  {
    path: '',
    component: OrganizationComponent,
    children: [
      {
        path: 'list',
        // redirectTo: 'dashboard',
        pathMatch: 'full',
        component: ListComponent,
      },
    //   {
        // path: 'add',
        // redirectTo: 'dashboard',
        // pathMatch: 'full',
        // component: AddComponent,
    //   },
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrganizationRoutingModule { }
