import { Component, OnInit , ViewChild , } from '@angular/core';
import { OrganizationServices } from '../services/organization.service';
import { Router } from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  displayedColumns: string[] = ['code', 'name', 'creation', 'options'];
  dataSource;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(  private _org: OrganizationServices , public router: Router) {
 
   }

  ngOnInit() {
    this._org.getOrganizations().then((res:any)=>{
      console.log(res);
      this.dataSource = new MatTableDataSource<any>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }
  add(){
    this.router.navigate(["/users/add"])
  }

}
