import { Component, OnInit , EventEmitter , ViewChild} from '@angular/core';
import { MenuServices } from '../theme/services/menu.service';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';


@Component({
  selector: 'app-user',
  templateUrl: './users.component.html',
//   styleUrls: ['./app.component.scss']
})
export class UserComponent {
  title = 'Usarios';
  displayedColumns: string[] = ['username', 'email', 'organization', 'role' , 'jobtitle','phone' , 'options'];
  subscription;
  dataSource = [];
  @ViewChild('drawer' , null ) sidenav: MatSidenav;
  constructor( private menu : MenuServices  , public router: Router) {
    this.subscription = this.menu.openMenu().subscribe(status => {
      console.log(status);
      if(status.status == true){
        this.sidenav.toggle();
      }
    });
   }
}
