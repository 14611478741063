import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { UserServices } from "../users/services/user.service";
@Component({
  selector: "app-recovery",
  templateUrl: "./recovery.component.html",
  styleUrls: ["./recovery.component.scss"],
})
export class RecoveryComponent implements OnInit {
  public loginForm: FormGroup;
  constructor(private route: ActivatedRoute, private _users: UserServices) {}

  ngOnInit() {
    this.loginForm = new FormGroup({
      password: new FormControl("", [
        Validators.required,
        Validators.maxLength(20),
      ]),
      repassword: new FormControl("", [
        Validators.required,
        Validators.maxLength(20),
      ]),
    });
  }
  changePass() {
    const token = this.route.snapshot.paramMap.get("token");
    console.log("token", token);
    if (!token) {
      console.log("No se puede cambiar clave");
    } else {
      if (
        this.loginForm.controls.password.value ==
        this.loginForm.controls.repassword.value
      ) {
        this._users
          .changePassword(this.loginForm.controls.password.value, token)
          .then((res: any) => {
            if (res.success) {
              console.log("pass word cambiado");
              alert("Tu contraseña se ha actualizado");
            } else {
              alert("Ha ocurrido un error actualizando contraseña.");
            }
          });
      }
    }
  }
}
