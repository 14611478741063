import { Component, OnInit , ViewChild , EventEmitter} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import { MenuServices } from '../theme/services/menu.service'
import { from } from 'rxjs';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  showFiller = false;
  subscription;
  @ViewChild('drawer' , null ) sidenav: MatSidenav;
  constructor( private menu : MenuServices) {
    this.subscription = this.menu.openMenu().subscribe(status => {
      console.log(status);
      if(status.status == true){
        this.sidenav.toggle();
      }
    });

   }

  ngOnInit() {

  }


}
