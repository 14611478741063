import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";
import { BqAuthService } from "./services/auth/bq-auth.service";
import { LoginComponent } from "./login/login.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { RecoveryComponent } from "./recovery/recovery.component";

const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "recovery",
    component: RecoveryComponent,
  },
  {
    path: "dashboard",
    // loadChildren: 'app/ecm/ecm.module#PagesModule',
    canActivate: [BqAuthService],
    component: DashboardComponent,
    // data: {authorization: '360ECM'}
  },
  {
    path: "users",
    loadChildren: "./users/users.module#UsersModule",
    canActivate: [BqAuthService],
    // data: {authorization: '360CVM'}
  },
  {
    path: "organization",
    loadChildren: "./organization/organization.module#OrganizationModule",
    canActivate: [BqAuthService],
    // data: {authorization: '360CVM'}
  },
  { path: "**", redirectTo: "/dashboard" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
