import { Component, OnInit, ViewChild } from "@angular/core";
import { UserServices } from "../services/user.service";
import { ExcelService } from "../../services/common/ExcelService.services";
import { Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class ListComponent implements OnInit {
  displayedColumns: string[] = [
    "username",
    "email",
    "organization",
    "role",
    "jobtitle",
    "phone",
    "options",
  ];
  dataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private _users: UserServices,
    public router: Router,
    public excel: ExcelService
  ) {}

  ngOnInit() {
    this._users.getUsers().then((res: any) => {
      console.log(res);
      this.dataSource = new MatTableDataSource<any>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  add() {
    this.router.navigate(["/users/add"]);
  }
  setting(item: any) {
    console.log(item);
    delete item["password"];
    let data = JSON.stringify(item);
    this.router.navigate(["/users/setting", { user: data }]);
  }
  getInactiveUsers() {
    this._users.inactiveUsers().then((res: any) => {
      let aux = [];
      console.log(res.items);
      for (let item of res.items) {
        let jsonAux = {
          nombre: item.name,
          apellido: item.surname,
          email: item.email,
          organizacion: item.organization.name,
          Ultima_Conexion: item.lastLogin,
        };
        aux.push(jsonAux);
      }
      this.excel.exportAsExcelFile(aux, "inactiveUsers");
    });
  }
  getActivityUsers() {
    console.log("Activity users");
    this._users.activityUsersAdmin().then((res: any) => {
      console.log("res ", res);
      let aux = [];
      for (let item of res.items) {
        console.log(item);
        let name;
        let surname;
        if (!item.user) {
          name = "n/e ";
          surname = "n/e";
        } else {
          name = item.user.username;
          surname = item.user.surname;
        }
        let jsonAux = {
          nombre: name,
          apellido: surname,
          actividad: item.description,
          fecha: item.fecha,
        };
        aux.push(jsonAux);
      }
      this.excel.exportAsExcelFile(aux, "Activity users");
    });
  }
}
