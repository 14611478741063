import { NgModule } from '@angular/core';
import { UsersRoutingModule } from "./users-routing.module"
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { UserComponent } from './users.component';
import {  ThemeModule } from '../theme/theme.module';
import { UserServices} from './services/user.service';
import { AddComponent } from './add/add.component';
import { SettingComponent } from './setting/setting.component';

@NgModule({
  declarations: [
    ListComponent,
     AddComponent ,
    UserComponent,
    SettingComponent],
  imports: [
    CommonModule,
    UsersRoutingModule,
    ThemeModule
  ],
  providers:[
    UserServices
  ]
})
export class UsersModule { }
