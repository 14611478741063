import { CommonDataService } from '../common/commonData.services';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CanActivate } from '@angular/router';
import { Router , ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import * as moment from 'moment';

@Injectable()
export class BqAuthService implements CanActivate {
  private _isLogged: boolean;
  private _userData: any;

  constructor(
    private _http: HttpClient,
    private router: Router,
    private commonApi: CommonDataService
  ) {
    this._isLogged = false;
    this._userData = null;
  }

  login(username: string, password: string) {
    return new Promise((resolve, reject) => {
      this._http
        .post(environment.apiUrl + '/login', {
          email: username,
          password: password,
        })
        .toPromise()
        .then((loginRes: any) => {
          console.log('LOgin', loginRes);
          if(loginRes.error){
            reject(loginRes);            
          }else{
          this._userData = loginRes.user;
          this._isLogged = true;
          window.sessionStorage.setItem('BQT.token', loginRes.token);
          window.sessionStorage.setItem('BQT.lcheck', moment().toISOString());
          window.sessionStorage.setItem('BQT.cudat', JSON.stringify(loginRes));
          resolve(loginRes);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  canActivate(route ? : ActivatedRouteSnapshot , state ?: RouterStateSnapshot) {
    // console.log(route ,  state);
    // console.log(route.data["authorization"]);
    let modulo;
    if(route != undefined   ){
       modulo = route.data["authorization"];
    }else{
      modulo = 'welcome';
    }
    const uToken = window.sessionStorage.getItem('BQT.token');
    const uData = window.sessionStorage.getItem('BQT.cudat');
    const lCheck = window.sessionStorage.getItem('BQT.lcheck');

    if (lCheck && moment().diff(moment(lCheck), 's') >= 5) {
      this.commonApi.getBackendStatus().subscribe(
        status => {
          if (status && status.maintenance && status.maintenance === true) {
            this._isLogged = false;
            window.sessionStorage.removeItem('BQT.token');
            window.sessionStorage.removeItem('BQT.lcheck');
            window.sessionStorage.removeItem('BQT.cudat');
            alert(
              'El sistema va a pasar a modo mantenimiento, por favor vuelva a acceder pasados unos minutos!',
            );
            this._userData = null;
            this.router.navigate(['/login']);
          }
        },
        error => {
          this._isLogged = false;
          window.sessionStorage.removeItem('BQT.token');
          window.sessionStorage.removeItem('BQT.lcheck');
          window.sessionStorage.removeItem('BQT.cudat');
          alert(
            'No se ha podido contactar con el servidor, por favor vuelva a intentarlo pasados unos minutos.\n' +
              'Si pasados unos minutos sigue sin poder conectar, pongase en contacto con soporte.',
          );
          this._userData = null;
          this.router.navigate(['/login']);
        },
      );
    }

    if (!uToken || !uData) {
      this._userData = null;
      this._isLogged = false;
      window.sessionStorage.removeItem('BQT.token');
      window.sessionStorage.removeItem('BQT.lcheck');
      window.sessionStorage.removeItem('BQT.cudat');

      this.router.navigate(['/login']);
      return false;
    } else {
      this._isLogged = Boolean(window.sessionStorage.getItem('BQT.token'));
      this._userData = JSON.parse(window.sessionStorage.getItem('BQT.cudat'));
      // if(modulo == "welcome"){
      //   return true;
      // }else{
      //   let flag = false;
      //   for(let md of this._userData.modules){
      //     console.log(md);
      //     if(md == modulo){
      //       flag = true;
      //     }
      //   }
      //   return flag;
      // }
      return true;
    }
  }

  getUserData() {
    // console.log(this._userData);
    return this._userData;
  }

  isLogged() {
    return this.canActivate();
  }

  isDeveloper() {
    return this._userData.isDeveloper;
  }
}
