import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSlideToggleChange } from "@angular/material";
import { UserServices } from "../services/user.service";
import { MatSnackBar } from "@angular/material/snack-bar";
@Component({
  selector: "app-setting",
  templateUrl: "./setting.component.html",
  styleUrls: ["./setting.component.scss"],
})
export class SettingComponent implements OnInit {
  currentUser;
  panelOpenState = false;
  cvm;
  ecm;
  bvm;
  evm;
  groups;
  groups2;
  groups3;
  selg;
  selg2;
  selg3;
  selg4;
  groups4;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _users: UserServices,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    const data = this.route.snapshot.paramMap.get("user");
    this.currentUser = JSON.parse(data);
    console.log(this.currentUser);
    this.cvm = this.currentUser.cvm360;
    this.ecm = this.currentUser.ecm360;
    this.bvm = this.currentUser.bvm360;
    this.evm = this.currentUser.evm360;
    if (this.cvm == true) {
      this.getGroups();
    }
    if (this.bvm == true) {
      this.getGroupsBVM();
    }
    if (this.ecm == true) {
      this.getGroupsECM();
    }
  }
  public async toggle(event: MatSlideToggleChange) {
    console.log("toggle", event);
    this.cvm = event.checked;
    this.currentUser.cvm360 = event.checked;
    // this.getGroups();
    console.log(this.currentUser);
    let vr = this.currentUser.organization;
    this.currentUser.organization = vr._id;
    if (this.currentUser.role) {
      vr = this.currentUser.role;
      this.currentUser.role = vr._id;
    }
    if (this.cvm == true) {
      this.getGroups();
    }
  }
  connecTo(module) {
    console.log(module);
    if (module == "cvm") {
      const data = {
        id: this.currentUser._id,
        metadata: JSON.stringify(this.currentUser),
        group: this.selg,
      };
      this._users
        .updateUser(this.currentUser._id, this.currentUser)
        .then((res) => {
          console.log(res);
          this.currentUser = res.user;
          this._users.connectCVM(data).then((res) => {});
          this._snackBar.open("Se activo correctamente", "Ok", {
            duration: 2000,
          });
          this.router.navigate(["/users/list"]);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (module == "ecm") {
      const data = {
        id: this.currentUser._id,
        metadata: JSON.stringify(this.currentUser),
        group: this.selg3,
      };
      this._users
        .updateUser(this.currentUser._id, this.currentUser)
        .then((res) => {
          console.log(res);
          this.currentUser = res.user;
          this._users.connectECM(data).then((res) => {});
          this._snackBar.open("Se activo correctamente", "Ok", {
            duration: 2000,
          });
          this.router.navigate(["/users/list"]);
        })
        .catch((err) => {});
    } else if (module == "bvm") {
      console.log("Modulo bvm");
      const data = {
        id: this.currentUser._id,
        metadata: JSON.stringify(this.currentUser),
        group: this.selg2,
      };
      this._users
        .updateUser(this.currentUser._id, this.currentUser)
        .then((res) => {
          console.log(res);
          this.currentUser = res.user;
          this._users.connectBVM(data).then((res) => {
            this._snackBar.open("Se activo correctamente", "Ok", {
              duration: 2000,
            });
            this.router.navigate(["/users/list"]);
          });
        })
        .catch((err) => {});
    } else if (module == "evm") {
      console.log("Modulo evm");
      const data = {
        id: this.currentUser._id,
        metadata: JSON.stringify(this.currentUser),
        company: this.selg4,
      };
      console.log(data);
      this._users
        .updateUser(this.currentUser._id, this.currentUser)
        .then((res) => {
          console.log(res);
          this.currentUser = res.user;
          this._users.connectEVM(data).then((res) => {
            this._snackBar.open("Se activo correctamente", "Ok", {
              duration: 2000,
            });
            this.router.navigate(["/users/list"]);
          });
        })
        .catch((err) => {});
    }
  }

  getGroups() {
    this._users.getGroups().then((res) => {
      this.groups = res;
    });
  }
  getGroupsBVM() {
    this._users.getGroupsBVM().then((res) => {
      console.log(res);
      this.groups2 = res;
    });
  }
  getGroupsECM() {
    this._users.getGroupsECM().then((res) => {
      console.log(res);
      this.groups3 = res;
    });
  }
  activateEcm(event: MatSlideToggleChange) {
    console.log("toggle", event);
    this.ecm = event.checked;
    this.currentUser.ecm360 = event.checked;
    // this.getGroups();
    console.log(this.currentUser);
    // let vr = this.currentUser.organization;
    // this.currentUser.organization = vr._id;
    // if( this.currentUser.role){
    //   vr = this.currentUser.role;
    //   this.currentUser.role = vr._id;
    // }
    if (this.ecm == true) {
      this.getGroupsECM();
    }
  }
  activateBvm(event: MatSlideToggleChange) {
    console.log("toggle", event);
    this.bvm = event.checked;
    this.currentUser.bvm360 = event.checked;
    // this.getGroups();
    // console.log(this.currentUser);
    // let vr = this.currentUser.organization;
    // this.currentUser.organization = vr._id;
    // if( this.currentUser.role){
    //   vr = this.currentUser.role;
    //   this.currentUser.role = vr._id;
    // }
    if (this.bvm == true) {
      this.getGroupsBVM();
    }
  }
  activateEvm(event: MatSlideToggleChange) {
    console.log("toggle", event);
    this.evm = event.checked;
    this.currentUser.evm360 = event.checked;
    // this.getGroups();
    // console.log(this.currentUser);
    // let vr = this.currentUser.organization;
    // this.currentUser.organization = vr._id;
    // if( this.currentUser.role){
    //   vr = this.currentUser.role;
    //   this.currentUser.role = vr._id;
    // }
    if (this.evm == true) {
      this.getBusinness();
      console.log(this.evm);
    }
  }
  getBusinness() {
    this._users.getGroupsEVM().then((res) => {
      console.log(res);
      if (res.ok == true) {
        this.groups4 = res.companies;
      }
    });
  }
}
