import { NgModule } from '@angular/core';
import { OrganizationRoutingModule } from "./organization-routing.module";
import { CommonModule } from '@angular/common';
import { ThemeModule } from "../theme/theme.module"
import { OrganizationComponent } from './organization.component';
import { ListComponent } from './list/list.component';
import { OrganizationServices } from './services/organization.service'

@NgModule({
  declarations: [OrganizationComponent, ListComponent],
  imports: [
    CommonModule,
    OrganizationRoutingModule,
    ThemeModule
  ],
  providers: [
    OrganizationServices
  ]
})
export class OrganizationModule { }
