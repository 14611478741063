import { Injectable , } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
@Injectable()
export class OrganizationServices  {
    constructor(private http: HttpClient) {
    
    }
    getOrganizations(){
        let url = environment.apiUrl + '/organization/all';
        return this.http.get(url).toPromise()
        .then((res:any) =>{
          console.log(res);
          return res.org;
        })
        .catch(err =>{
          console.log( "Error " , err );
        })
    }
    createUser(userData){
      let url = environment.apiUrl + '/users';
      return this.http.post(url , {userData}).toPromise()
      .then((res:any) =>{
        console.log(res);
        return res;
      })
      .catch(err =>{
        console.log( "Error " , err );
      })
    }
   
}