import { Injectable , Output , EventEmitter} from '@angular/core';
import { Observable, Subject } from 'rxjs';
@Injectable()
export class MenuServices  {
    menu = false;
    private config = new Subject<any>();
    constructor() {
    
    }
    openMenu(): Observable<any> {
        console.log("Open menu");
        if(this.menu === false){
            this.sendStatus(true);

        }else{
            this.sendStatus(false);
        }
        return this.config.asObservable();
      }
    
    sendStatus(status: boolean) {
        console.log("Status de corek es");
        console.log(status);
       this.config.next({ status: status });
     }
   
}