import { Observable, throwError } from "rxjs";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";

import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";

@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  log(text: string) {
    // console.log('[%cHttpInjector%c] %s', 'color: yellow', '', text );
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401 || err.status === 403) {
      window.sessionStorage.removeItem("BQT.token");
      window.sessionStorage.removeItem("BQT.cudat");
      this.router.navigate(["/login"]);
      return of(err.message);
    }
    return Observable.throw(err);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    console.log("Intercepting request: [" + req.method + "] " + req.url);
    // Clone the request to add the new header
    const uToken = window.sessionStorage.getItem("BQT.token");
    const clonedRequest = req.clone({
      headers: req.headers.append("AAT", uToken || ""),
    });
    return next.handle(clonedRequest).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.router.navigate(["/login"]);
        }
        return throwError(err);
      })
    );
  }
}
