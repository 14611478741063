import { Component, OnInit, EventEmitter, ViewChild } from "@angular/core";
import { UserServices } from "../services/user.service";
import { CommonDataService } from "../../services/common/commonData.services";
import { Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
@Component({
  selector: "app-add",
  templateUrl: "./add.component.html",
  styleUrls: ["./add.component.scss"],
})
export class AddComponent implements OnInit {
  public userForm: FormGroup;
  organizations;
  roles;
  constructor(
    private _users: UserServices,
    public router: Router,
    private common: CommonDataService
  ) {}

  async ngOnInit() {
    this.userForm = new FormGroup({
      username: new FormControl("", [Validators.required]),
      name: new FormControl("", [
        Validators.required,
        Validators.maxLength(14),
      ]),
      organization: new FormControl("", [Validators.required]),
      role: new FormControl("", [Validators.required]),
      surname: new FormControl("", [
        Validators.required,
        Validators.maxLength(17),
      ]),
      jobTitle: new FormControl("", [
        Validators.required,
        Validators.maxLength(20),
      ]),
      phone: new FormControl("", [Validators.required]),
      email: new FormControl("", [
        Validators.required,
        Validators.maxLength(60),
        Validators.email,
      ]),
      password: new FormControl("", [
        Validators.required,
        Validators.maxLength(20),
      ]),
      owner: new FormControl(false, [Validators.required]),
      active: new FormControl(true, [Validators.required]),
    });
    this.organizations = await this.common.getOrganization();
    this.roles = await this.common.getRoles();
    console.log(this.organizations);
    console.log(this.roles);
  }
  create() {
    console.log(this.userForm.controls);
    if (this.userForm.valid) {
      console.log(this.userForm.getRawValue());
      let data = this.userForm.getRawValue();
      this._users.createUser(data).then((res: any) => {
        console.log("res", res);
        if (res.ok == true) {
          this.router.navigate(["/users"]);
        }
      });
    }
  }
  cancel() {}
}
