import { Component, OnInit , ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MenuServices } from '../theme/services/menu.service';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {
  subscription;
  @ViewChild('drawer' , null ) sidenav: MatSidenav;
  constructor( private menu : MenuServices  ) {
    this.subscription = this.menu.openMenu().subscribe(status => {
      console.log(status);
      if(status.status == true){
        this.sidenav.toggle();
      }
    });
   }

  ngOnInit() {
  }

}
