import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListComponent } from './list/list.component';
import { AddComponent } from './add/add.component';
import { UserComponent } from './users.component';
import { SettingComponent } from './setting/setting.component';


const routes: Routes = [
  {
    path: '',
    component: UserComponent,
    children: [
      {
        path: 'list',
        // redirectTo: 'dashboard',
        // pathMatch: 'full',
        component: ListComponent,
      },
      {
        path: 'add',
        // redirectTo: 'dashboard',
        // pathMatch: 'full',
        component: AddComponent,
      },
      {
        path: 'setting',
        // redirectTo: 'dashboard',
        // pathMatch: 'full',
        component: SettingComponent,
      },
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
