import { Component, OnInit } from '@angular/core';
import { BqAuthService } from '../../services/auth/bq-auth.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  currentUser;
  initialChart;
  active = "";
  constructor(
    private _bqAuth: BqAuthService,
    public router: Router
  ) { }

  ngOnInit() {
    let user = this._bqAuth.getUserData();
    this.currentUser = user.user;
    console.log(this.currentUser);
    this.initialChart = this.currentUser.username.substring(0, 1);
    this.active = localStorage.getItem('current');
  }
  goUsers(){
    localStorage.setItem('current' ,'users');
    this.router.navigate(["users/"])
    
  }
  goHome(){
    localStorage.setItem('current' ,'home');
    this.router.navigate(["/dashboard"])
  }
  goOrg(){
    localStorage.setItem('current' ,'org');
    this.router.navigate(["/organization"])
  }
}

